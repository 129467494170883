@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Rock+Salt&family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap");

.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  background: #efe6d9;
}

.home-page {
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  width: 450px;
  height: 450px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

svg {
  height: 34px;
  width: 50px;
}

@media (max-width: 500px) {
  .home-page {
    width: 80%;
  }
}

.heading,
.nav {
  text-transform: uppercase;
  margin-left: 20px;
  letter-spacing: 2px;
  font-size: 0.8rem;
  margin-top: 9px;
  font-weight: 600;
  color: #c4a484;
}

.name {
  font-size: 40px;
  margin-bottom: 70px;
}

.line {
  width: 105px;
  height: 1px;
  background: #c4a484;
}

.nav {
  position: relative;
  margin-bottom: 9px;
  display: flex;
}

#main:hover,
#about:hover,
#contact:hover {
  cursor: pointer;
  color: #000000;
}

.body-div {
  font-family: "Gilda Display", serif;
}

.aboutme-summary,
.contact-summary {
  font-family: "Gilda Display", serif;
  font-weight: 500;
}

.aboutme-summary {
  padding: 25px;
}

.contact-summary a {
  color: #000000;
  text-decoration: none;
}

.contact-summary a:hover {
  color: #c4a484;
}

.contact-summary a:visited {
  text-decoration: none;
}

#project-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 2em;
  overflow: auto;
}

.card-body {
  background: #fff 50%;
  background-size: cover;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.card-body:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translate3D(0, -1px, 0);
}

.card-content {
  margin-top: 109px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 215px;
}

.card-content a {
  display: block;
  color: #6a737d;
}

.card-title {
  margin: 0 0 0.5em;
  font-size: 20px;
}

.card-title,
.card-text {
  font-family: "Gilda Display", serif;
}

.card-text {
  padding: 5px;
}

.project-code {
  display: block;
  margin-bottom: 4px;
  color: #c4a484;
  font-size: 12px;
  line-height: 1.15em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.card-footer {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.card-footer i {
  margin-left: 4px;
  margin-right: 4px;
}

#repoLink {
  margin-left: -15px;
}

.card-content i:hover {
  color: #6a737d !important;
}
